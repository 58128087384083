import styles from './ProgressBar.css'

export function ProgressBar({ progress, total, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={styles.layout}>
        <div className={styles.track}>
          <div className={styles.bar} style={{ width: `${100 * progress / total}%` }} />
        </div>
        <span className={styles.text}>{progress}/{total}</span>
      </div>
    </div>
  )
}
