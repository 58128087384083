import { StepsProgressOverview } from './StepsProgressOverview'
import { FirebaseContext } from '/domain/FirebaseContext'

export default function StepsProgressOverviewApp() {
  return (
    <FirebaseContext>
      <StepsProgressOverview />
    </FirebaseContext>
  )
}
