import { statements } from './statements'

export default {
  ...statementWithOutIcons(),
}

function statementWithOutIcons() {
  return Object.entries(statements).reduce((result, [key, value]) => {
    result[key] = {
      id: value.id,
      statement: value.statement,
      formFields: value.formFields
    }

    return result
  }, {})
}
