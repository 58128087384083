import { Icon } from '/features/buildingBlocks/Icon'
import { ProgressBar } from '/features/buildingBlocks/ProgressBar'
import { IconCheckmarkGreen, IconCheckmarkLightblue } from '/icons'
import { ButtonLink } from '/features/buildingBlocks/Button'
import { routeMap } from '/routeMap'
import { useFirebaseValue } from '/machinery/useFirebaseDatabase'
import { ref } from 'firebase/database'
import statements from '/data/statements.json'
import { Loader } from '/features/buildingBlocks/Loader'
import { EMAIL_IS_SENT_KEY, useLocalStorage } from '/machinery/useLocalStorage'

import styles from './StepsProgressOverview.css'

export function StepsProgressOverview() {
  const { isFetching, step1, step2, step3 } = useStepsInformation()

  if (isFetching)
    return <Loader />

  return (
    <section className={styles.component}>
      <div className={styles.step}>
        <div className={styles.stepProgress}>
          <ProgressIcon isCompleted={step1.isCompleted} />
        </div>
        <div className={styles.stepContent}>
          <header>
            <h2>Stap 1</h2>
            <p>Uw dagelijks leven</p>
          </header>
          <StepAction step={step1} />
        </div>
      </div>

      <div className={styles.step}>
        <div className={styles.stepProgress}>
          <ProgressIcon isCompleted={step2.isCompleted} />
        </div>
        <div className={styles.stepContent}>
          <header>
            <h2>Stap 2</h2>
            <p>Toelichting</p>
          </header>
          {step1.isCompleted && (
            <StepAction step={step2} />
          )}
        </div>
      </div>

      <div className={styles.step}>
        <div className={styles.stepProgress}>
          <ProgressIcon isCompleted={step3.emailIsSent} />
        </div>
        <div className={styles.stepContent}>
          <header>
            <h2>Stap 3</h2>
            <p>Uw samenvatting</p>
          </header>
          {step2.isCompleted && (
            <ButtonLink href={step3.link}>Bekijk samenvatting</ButtonLink>
          )}
        </div>
      </div>
    </section>
  )
}

function StepAction({ step }) {
  const { progress, total, link, editLink, isCompleted } = step

  return (
    <>
      <ProgressBar {...{ progress, total }} layoutClassName={styles.progressBarLayout} />
      {!isCompleted
        ? <ButtonLink href={progress ? editLink : link}> {progress > 0 ? 'Verder met invullen' : 'Start'} </ButtonLink>
        : <ButtonLink color='blue' href={editLink}>Aanpassen</ButtonLink>}
    </>
  )
}

function ProgressIcon({ isCompleted }) {
  const icon = isCompleted ? IconCheckmarkGreen : IconCheckmarkLightblue

  return (
    <Icon {...{ icon }} />
  )
}

function useStepsInformation() {
  const [emailIsSent] = useLocalStorage({ key: EMAIL_IS_SENT_KEY, fallbackValue: false })

  const { data, isFetching } = useFirebaseValue({
    reference: ({ user, database }) => ref(database, `/users/${user.uid}/statements`)
  })

  const entries = !isFetching ? Object.values(data ?? {}) : []

  const minStatementsToFillIn = 4
  const step2Progress = entries.filter((x) => Boolean(x.form)).length
  const step2Total = entries.filter((x) => x.selected).length
  const step2IsCompleted = step2Progress >= minStatementsToFillIn
    || (step2Total > 0 && step2Progress === step2Total)

  return {
    isFetching,
    step1: {
      progress: entries.length,
      total: Object.keys(statements).length,
      isCompleted: entries.length === Object.keys(statements).length,
      link: routeMap.app.stepOne.intro({ language: 'nl' }),
      editLink: routeMap.app.stepOne.statements({ language: 'nl' })
    },
    step2: {
      progress: step2Progress,
      total: step2Total,
      isCompleted: step2IsCompleted,
      link: routeMap.app.stepTwo.intro({ language: 'nl' }),
      editLink: routeMap.app.stepTwo.overview({ language: 'nl' })
    },
    step3: {
      emailIsSent,
      link: routeMap.app.stepThree.intro({ language: 'nl' })
    }
  }
}
